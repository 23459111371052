const Gifts = () => {

    const giftWorkshop = 'https://buy.stripe.com/cN25mJ236dtedvWbIS';
    const giftOL = 'https://buy.stripe.com/28oeXj37afBm0JabIN';
    const giftMem = 'https://buy.stripe.com/eVa7uR6jm60M77yeV1';
    const giftSal = 'https://buy.stripe.com/aEU9CZdLO88U9fG28j';


    // Gemma facilitates each session so skilfully so each and every one of us participating feels empowered and equal. Beginning my day with Write & Shine absolutely makes the day juicier and richer without question. —Deena


    return (
        <> 
        <div className='banner'></div>
        <section className='line'>
            <div className='intro'>
            <h2>Gift vouchers</h2>
                <p className='opening'>Give the gift of creativity!</p>
                <p>Our peaceful <a href='https://booking.write-and-shine.com' className='link'>morning workshops</a> and inspiring <a href='https://write-and-shine.com/online/' className='link'>online library</a> are perfect gifts for those who want to commit to their writing, or simply want to add a little more creativity to their lives.</p>
                <p>Please contact us if you have any questions at hello@write-and-shine.com</p>
            </div>
        </section>
        <section className='km'>
        <h3 className='center'>How our vouchers work</h3>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-0'></div>
            </div>
            <div className='right'>
            <p>Once you make your purchase we'll email you a voucher containing a unique code that can be redeemed across our programme. This will be sent within 48 hours.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-1'></div>
            </div>
            <div className='right'>
            <p>You can gift any part of our programme, from individual workshops to our <a href='https://write-and-shine.com/online/' className='link'>online library</a>, from a month's <a href='https://membership.write-and-shine.com/' className='link'>membership</a> to a <a href='https://salon.write-and-shine.com' className='link'>salon programme</a>.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-2'></div>
            </div>
            <div className='right'>
            <p>Our vouchers are available at any value, so please contact us at hello@write-and-shine.com to discuss.</p>
            </div>
        </div>

        </section>

        <section className='main'>

        <div className='item-wrap'>
            <div className='left item-img0'></div>
            <div className='right'>
            <h4>Workshops - £23 each</h4>
            <p className='blurb'>Perfect for those who want a taste of early morning creativity. You can select how many workshops you would like to gift by adjusting the quantity at the checkout.</p>
            <p className='quotes'>The group atmosphere is supportive and welcoming. I'd definitely recommend setting your alarm a little earlier and giving it a go! &mdash;Harpreet</p>
            <a href={`${giftWorkshop}`}>
                <button className='book btnright'>Buy now</button>
            </a>
            </div>
      </div>


      <div className='item-wrap'>
            <div className='left item-img1'></div>
            <div className='right'>
            <h4>Online library - £40</h4>
            <p className='blurb'>An annual subscription for our online library. With over 175 self-directed courses, it provides thoughtful timed exercises inspired by the natural world and taking a closer look at our everyday lives.</p>
            <p className='blurb'>Our library also includes guest courses penned by Write & Shine's guest tutors such as Rowan Hisayo Buchanan, Emily Berry and Vanessa Onwuemezi.</p>
            <a href={`${giftOL}`}>
                <button className='book btnright'>Buy now</button>
            </a>
            </div>
      </div>

      <div className='item-wrap'>
            <div className='left item-img3'></div>
            <div className='right'>
            <h4>Membership - £100</h4>
            <p className='blurb'>One month membership. A unique and inspiring gift offering someone the chance to focus on writing and creativity on a regular basis. It includes access to all events in our core programme, including workshops and morning writing retreats, as well as our online library. Please note, this excludes our salons.</p>
            <p className='quotes'>Write & Shine morning workshops have been rocket fuel for my writing life for several years now. I love being part of such an inspirational and supportive writing community. &mdash;Terri</p>
            <a href={`${giftMem}`}>
                <button className='book btnright'>Buy now</button>
            </a>
            </div>
      </div>

      <div className='item-wrap'>
            <div className='left item-img2'></div>
            <div className='right'>
            <h4>Salon - £199</h4>
            <p className='blurb'>Our salons are an ideal present for someone who wants to commit to their writing. They run twice a year virtually in May and November, virtually via Zoom, and are a week of workshops and inspiring guest speakers.</p>
            <p className='quotes'>It's made me more committed to my writing and helped me to generate a fantastic range of work &ndash; flash fiction, poetry, life writing, journal articles. &mdash;Shamini</p>
            <a href={`${giftSal}`}>
                <button className='book btnright'>Buy now</button>
            </a>
            </div>
      </div>
   
      </section>
  

      <section className='main'>

        <p className='center' style={{padding:'10px 30px 10px 30px'}}>Please note: vouchers are valid for 12 months from date of purchase.</p>

     </section>



      </>
    )
}

export default Gifts
